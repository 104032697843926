import "./App.css";
import { Routes, Route } from "react-router-dom";
// import ImageCropper from "./pages/ImageCropper";
// import ImageUpload from "./pages/ImageUpload";
import ImageCrp from "./pages/ImageCrp";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<ImageCrp />} />
        {/* <Route path="/ImageCrp" element={<ImageCrp />} /> */}
        {/* <Route path="/canvasPreview" element={</>} /> */}
      </Routes>
    </div>
  );
}

export default App;
